import Avo from './Avo';
import * as Inspector from 'avo-inspector';

export default function initAvo() {
    const systemProps = window.AVO_SYSTEM_PROPERTIES;
    const isProd = systemProps.ns_environment === 'prod';
    const isUnittest = systemProps.ns_environment === 'unittest';
    const inspector = new Inspector.AvoInspector({
        apiKey: Avo.avoInspectorApiKey,
        env: isProd ? 'prod' : 'dev',
        version: '3006674',
        appName: 'NSDesign'
    });
    Avo.initAvo(
        {
            env: isProd ? 'prod' : 'dev',
            inspector: !isUnittest ? inspector : undefined,
            avoLogger: {
                logDebug(env) {
                    return env === 'prod' || isUnittest;
                },
                logError(env) {
                    return env === 'prod' || isUnittest;
                },
                logWarn(env) {
                    return env === 'prod' || isUnittest;
                }
            },
            webDebugger: false
        },
        {
            platform: 'web',
            nsEnvironment: systemProps.ns_environment,
            datalayerSource: 'AVO',
            language: systemProps.language,
            preview: systemProps.preview
        },
        null,
        {
            logEvent: (eventName, eventProps) => {
                window.nsDataQueue.push({
                    event: eventName,
                    ...eventProps
                });
            }
        }
    );
}
