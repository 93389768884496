import EventEmitter from 'wolfy87-eventemitter';
import Responsive from '../../utils/Responsive';
import DataCollector from '../../utils/datacollector/DataCollector';
import merge from 'lodash/merge';

class Collapsible extends EventEmitter {
    static DEFAULT_CONFIG = {
        selectors: {
            headerSelector: '.collapsible__header'
        },
        classNames: {
            isExpanded: 'is-expanded',
            isActive: 'is-active'
        },
        smallOnly: false
    };

    static EVENTS = {
        expand: 'Collapsible.EVENTS.expand',
        collapse: 'Collapsible.EVENTS.collapse'
    };

    #abortController = new AbortController();
    #clickEventHandler = (e) => this.#toggleCollapsible(e);
    #keypressEventHandler = (e) => {
        if (e.key === 'Enter') {
            this.#toggleCollapsible(e);
        }
    };

    /**
     * The Collapsible component contains a header and a body and
     * allows the body to be expanded/collapsed by clicking the header
     * @param node: any parent containing the header and body
     * @param config: allows specifying header and body selectors
     * @constructor
     */
    constructor(node, config) {
        super();

        this.node = node;
        this.config = merge({}, Collapsible.DEFAULT_CONFIG, config);
        this.init();
    }

    init() {
        this.header = this.node.querySelector(this.config.selectors.headerSelector);

        if (!this.header) {
            console.error('[generic/ui/Collapsible] Configuration error: no header element found for:', this.node);
            return;
        }
        this.body = this.header.nextElementSibling;

        Responsive.on(Responsive.EVENTS.crossedBreakpoint, () => this.#setCollapsibleState());

        this.#setCollapsibleState();

        // expose events to Data Collector
        DataCollector.subscribe(this, Collapsible.EVENTS);
    }

    /**
     * Attach listeners to objects
     */
    #attachListeners() {
        this.header.addEventListener('click', this.#clickEventHandler, { signal: this.#abortController.signal });
        this.header.addEventListener('keypress', this.#keypressEventHandler, { signal: this.#abortController.signal });
    }

    /**
     * Detach listeners to objects
     */
    #detachListeners() {
        this.#abortController.abort();
        this.#abortController = new AbortController();
    }

    /**
     * Enrich the component with JS-specific properties when necessary
     */
    #setCollapsibleState() {
        this.#detachListeners();
        let isSmallDevice = !Responsive.testBreakpoint('m');
        if (isSmallDevice || !this.config.smallOnly) {
            this.node.classList.add('is-collapsible');
            this.#attachListeners();
            this.#setAccessibilityProperties(true);
        } else {
            this.node.classList.remove('is-collapsible');
            this.#setAccessibilityProperties(false);
        }
    }

    /**
     * Toggle functionality
     */
    #toggleCollapsible(event) {
        let header = event.currentTarget;
        header.classList.toggle(this.config.classNames.isActive);
        header.setAttribute('aria-expanded', header.getAttribute('aria-expanded') === 'false');
        const body = header.nextElementSibling;
        body.classList.toggle(this.config.classNames.isExpanded);
        body.setAttribute('aria-hidden', body.getAttribute('aria-hidden') === 'false');
        let emitEvent = header.classList.contains(this.config.classNames.isActive)
            ? Collapsible.EVENTS.expand
            : Collapsible.EVENTS.collapse;
        this.emitEvent(emitEvent, [{ trigger: header }]);
    }

    /**
     * Set the desired accessibility properties
     * @param {boolean} value: should they be added (true) or removed (false)
     */
    #setAccessibilityProperties(value) {
        if (value) {
            this.header.setAttribute('tabindex', 0);
            this.header.setAttribute('aria-expanded', this.header.classList.contains(this.config.isExpanded));
            this.header.setAttribute('role', 'button');
            this.body.setAttribute('aria-hidden', !this.header.classList.contains(this.config.isExpanded));
            this.body.setAttribute('aria-live', 'polite');
        } else {
            this.header.removeAttribute('tabindex');
            this.header.removeAttribute('aria-expanded');
            this.header.removeAttribute('role');
            this.body.removeAttribute('aria-hidden');
            this.body.removeAttribute('aria-live');
        }
    }
}

export default Collapsible;
