import ChatUtil from '../../utils/ChatUtil';
import ChatObserver from '../../utils/ChatObserver';
import merge from 'lodash/merge';
import DOMUtils from '../../utils/DOMUtils';
import { ChatClickSource } from '../../utils/Avo';

class LiveChat {
    static DEFAULT_CONFIG = {
        disabledClass: 'is-disabled',
        chatDisableElementSelector: 'section, a, button',
        chatButtonSelector: 'a, button',
        chatStatusMessageAttribute: 'data-chat-status',
        chatStatusMessageClass: null,
        chatStatusMessageContainerSelector: '[data-chat-status-container]',
        chatStatusAvailable: null,
        chatStatusClosed: 'nu gesloten',
        chatStatusDisabled: 'niet beschikbaar'
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, LiveChat.DEFAULT_CONFIG, config);
        this.chatButton = this.node.querySelector(this.config.chatButtonSelector);
        this.chatDisableElements = this.node.querySelectorAll(this.config.chatDisableElementSelector);
        this.chatStatusMessageContainer = this.node.querySelector(this.config.chatStatusMessageContainerSelector);
        this.bindEvents();
    }

    bindEvents() {
        ChatObserver.on(ChatObserver.EVENTS.disabled, () => {
            this.setLiveChatText(this.config.chatStatusDisabled, false);
        });
        ChatObserver.on(ChatObserver.EVENTS.offline, () => {
            this.setLiveChatText(this.config.chatStatusClosed, false);
        });
        ChatObserver.on(ChatObserver.EVENTS.online, () => {
            this.setLiveChatText(this.config.chatStatusAvailable, true);
        });

        this.chatButton.addEventListener('click', (event) => {
            event.preventDefault();

            if (!ChatUtil.checkChatReady()) {
                return false;
            } else {
                ChatUtil.showChatbot(ChatClickSource.LIVE_CHAT);
            }
        });
    }

    setLiveChatText(text, enabled) {
        let statusElement = this.chatStatusMessageContainer.querySelector('[' + this.config.chatStatusMessageAttribute + ']');
        if (text === null) {
            statusElement?.remove();
        } else {
            if (statusElement) {
                statusElement.textContent = text;
            } else {
                this.chatStatusMessageContainer.append(
                    DOMUtils.createElementFromHTML(
                        `<span class="${this.config.chatStatusMessageClass}" ${this.config.chatStatusMessageAttribute}="">${text}</span>`
                    )
                );
            }
        }
        if (enabled) {
            this.chatDisableElements.forEach((element) => element.classList.remove(this.config.disabledClass));
            if (this.chatButton.matches('button')) {
                this.chatButton.disabled = false;
            }
        } else {
            this.chatDisableElements.forEach((element) => element.classList.add(this.config.disabledClass));
            if (this.chatButton.matches('button')) {
                this.chatButton.disabled = true;
            }
        }
    }
}

export default LiveChat;
