import Responsive from '../../utils/Responsive';
import throttle from 'lodash/throttle';
import merge from 'lodash/merge';
import DOMUtils from '../../utils/DOMUtils';

/**
 * This component checks if elements that are placed on a horizontal row fit
 * on that row next to each-other. If they do not fit on one row, the elements
 * will all get the same width, based on the widest element, so the elements are
 * aligned vertically.
 */
class EqualWidth {
    static DEFAULT_CONFIG = {
        maxWidth: 0,
        childrenTotalWidth: 0,
        childrenWidth: [],
        widthIsSet: false
    };

    #abortController = new AbortController();
    #resizeListener = throttle(() => {
        if (Responsive.testBreakpoint('m')) {
            this.#abortController.abort();
            this.#abortController = new AbortController();
            this.init();
        }
    }, 20);

    constructor(node, config) {
        this.node = node;
        this.nodeChildren = Array.from(this.node.children);
        this.config = merge({}, EqualWidth.DEFAULT_CONFIG, config);

        // Check if the viewport width is larger then breakpoint 'm' to
        // initialize the components. Otherwise listen for the resize event
        if (Responsive.testBreakpoint('m')) {
            this.init();
        } else {
            window.addEventListener('resize', this.#resizeListener, { signal: this.#abortController.signal });
        }
    }

    /**
     * Init
     */
    init() {
        this.getOriginalChildrenWidth();
        this.config.maxWidth = this.getMaxWidth();
        this.update();

        Responsive.on(Responsive.EVENTS.resized, () => this.update());
    }

    /**
     * Save the width of all the children and save the total children width
     */
    getOriginalChildrenWidth() {
        this.nodeChildren.forEach((element) => {
            let elementWidth = element.getBoundingClientRect().width;
            let elementOuterWidth = DOMUtils.outerWidthWithMargins(element);

            this.config.childrenWidth.push(elementWidth);
            this.config.childrenTotalWidth += elementOuterWidth;
        });
    }

    /**
     * Get the highest width of all elements
     * Save the highest width on this.config.maxWidth
     */
    getMaxWidth() {
        return Math.max.apply(Math, this.config.childrenWidth);
    }

    /**
     * Check if element width should be set, or if the elements fit on one row
     *
     * Set element widths if
     * - total children width is wider then its parent
     * - screen width is wider then breakpoint 'm'
     */
    update() {
        if (this.config.childrenTotalWidth >= this.node.getBoundingClientRect().width && Responsive.testBreakpoint('m')) {
            /**
             * Make sure the widths are not set on every resize if they are
             * already set
             */
            if (!this.config.widthIsSet) {
                this.config.widthIsSet = true;
                this.setElementWidth();
            }
        } else {
            /**
             * Make sure the reset is not called if the elements are already
             * reset
             */
            if (this.config.widthIsSet) {
                this.config.widthIsSet = false;
                this.resetElementWidth();
            }
        }
    }

    /**
     * Set the highest width on the elements
     */
    setElementWidth() {
        this.nodeChildren.forEach((child) => (child.style.width = `${this.config.maxWidth}px`));
    }

    /**
     * Reset element width
     */
    resetElementWidth() {
        this.nodeChildren.forEach((child) => (child.style.width = ''));
    }
}

export default EqualWidth;
