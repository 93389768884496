import EventEmitter from 'wolfy87-eventemitter';
import ComponentManager from '../../generic/utils/ComponentManager';
import DataCollector from '../../generic/utils/datacollector/DataCollector';
import Responsive from '../../generic/utils/Responsive';
import setTemporaryFocus from '../../generic/utils/setTemporaryFocus';
import TabSequenceWatch from '../../generic/ui/TabSequenceWatch';
import ScrollBlocker from '../../generic/ui/ScrollBlocker';
import defaultOverlayTemplate from '../jstemplates/overlay-default.tpl';
import videoOverlayTemplate from '../jstemplates/overlay-video.tpl';
import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';

class Overlay extends EventEmitter {
    static SELECTORS = {
        overlayParent: '.overlay',
        closeButton: '.overlay__close',
        overlayFocusTarget: '.overlay__focus',
        scrollContainer: '.overlay__scrollContainer',
        scrollPositioner: '.overlay__contentPositioner',
        contentContainer: '[data-contentContainer]'
    };

    static EVENTS = {
        overlayIsOpen: 'Overlay.EVENTS.overlayIsOpen',
        overlayIsClosed: 'Overlay.EVENTS.overlayIsClosed'
    };

    static DEFAULT_SETTINGS = {
        type: 'default' // valid: 'default', 'video'
    };

    constructor(settings) {
        super();
        this.settings = merge({}, Overlay.DEFAULT_SETTINGS, settings);

        // create node from template.
        let template;
        if (this.settings.type === 'video') {
            template = videoOverlayTemplate;
        } else {
            template = defaultOverlayTemplate;
        }
        this.node = DOMUtils.createElementFromHTML(template);
        this.origin = null;
        this.overlayIsOpen = false;

        // get some elements
        this.scrollContainer = this.node.querySelector(Overlay.SELECTORS.scrollContainer);
        this.scrollPositioner = this.node.querySelector(Overlay.SELECTORS.scrollPositioner);
        this.contentContainer = this.node.querySelector(Overlay.SELECTORS.contentContainer);

        // catch all clicks on the overlay and determine which ones must result in an overlay.close().
        this.node.addEventListener('click', (event) => {
            let target = event.target;

            if (target.closest(Overlay.SELECTORS.closeButton) || target === this.scrollContainer) {
                event.preventDefault();
                this.close();
            }
        });

        // create unique id
        this.overlayId = 'overlay' + this.instanceCount++;

        // watch the tab sequence
        this.tabSequenceWatch = new TabSequenceWatch(this.node);

        // Allow DataCollector to listen to events emitted on object
        DataCollector.subscribe(this, this.EVENTS);
    }

    instanceCount = 0;

    #abortController = new AbortController();
    #keyupListener = (e) => {
        if (e.key === 'Escape' || e.code === 'Escape') {
            this.close();
        }
    };

    // watch breakpoints and close the overlay if it reaches it
    attachBreakPointListener(breakpoint) {
        Responsive.on(Responsive.EVENTS.crossedBreakpoint, () => {
            let closeOnBreakpoint = Responsive.testBreakpoint(breakpoint),
                overlayOpen = this.overlayIsOpen;

            if (overlayOpen && closeOnBreakpoint) {
                this.close();
            }
        });
    }

    setFullHeight() {
        this.scrollPositioner.classList.add('overlay__contentPositioner--fullHeight');
    }

    setContent(content, parent) {
        this.content = content;
        this.origin = parent;
        this.contentContainer.append(content);
    }

    setOpener(opener) {
        this.opener = opener;
    }

    open() {
        this.overlayIsOpen = true;
        this.emitEvent(Overlay.EVENTS.overlayIsOpen, [
            {
                id: this.overlayId,
                event: 'open'
            }
        ]);

        // add overlay to body
        document.body.append(this.node);
        this.node.classList.add('is-in-dom');

        // listen to keyboard close ('esc')
        window.addEventListener('keyup', this.#keyupListener, { signal: this.#abortController.signal });

        //check if overlay__focus is placed
        const focusTarget = this.scrollContainer.querySelector(Overlay.SELECTORS.overlayFocusTarget);
        if (focusTarget) {
            this.focusTarget = focusTarget;
        } else {
            this.focusTarget = this.scrollContainer;
        }

        // accessibiliy - focus on the overlay.
        setTemporaryFocus(this.focusTarget);

        // disable Scrolling by enabling ScrollBlocker
        ScrollBlocker.disableScroll();

        this.node.classList.add('is-visible');

        // (re)init any components in the content
        ComponentManager.initNode(this.content);

        this.tabSequenceWatch.enable();
    }

    displace(sibling) {
        this.node.prepend(sibling);
    }

    close() {
        if (this.overlayIsOpen) {
            this.overlayIsOpen = false;
            this.node.classList.remove('is-visible');
            this.tabSequenceWatch.disable();
            // remove keyboard listener
            this.#abortController.abort();

            /**
             * Resolve the deferred after the CSS animation is done
             * Animation duration is 300ms
             */
            setTimeout(() => {
                this.emitEvent(Overlay.EVENTS.overlayIsClosed, [
                    {
                        id: this.overlayId,
                        event: 'close'
                    }
                ]);

                // enable Scrolling by disabling ScrollBlocker
                ScrollBlocker.enableScroll();

                // let the opener know we have closed again.
                if (this.opener && this.opener.refocus) {
                    this.opener.refocus();
                }

                // destroy
                if (this.content) {
                    //if parent is defined, put it back
                    if (this.origin) {
                        this.origin.append(this.content);
                    } else {
                        this.content.remove();
                        this.content = null;
                    }
                }

                if (this.node) {
                    this.node.remove();
                    this.node = null;
                }

                this.scrollContainer = null;
                this.contentContainer = null;

                this.overlayId = null;
                this.scrollPosition = null;
                this.opener = null;
            }, 300);
        }
    }
}

export default Overlay;
