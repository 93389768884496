import publishTagmanagementEvent from '../publishTagmanagementEvent';
import DOMMutationObserver from '../DOMMutationObserver';
import FilterDataCollector from './FilterDataCollector';
import merge from 'lodash/merge';
import EventNamespaceHandlerUtils from '../EventNamespaceHandlerUtils';
import DOMUtils from '../DOMUtils';

/**
 * DataCollector (singleton) binds events to elements and
 * aggregates data from their corresponding data attributes.
 * @param config
 * @constructor
 */
class DataCollector {
    static CONFIG = {
        selectors: {
            dataAttribute: 'eventdata'
        }
    };

    constructor() {
        this.config = merge({}, DataCollector.CONFIG);
    }

    /**
     * Define data collectors and attach events
     */
    init() {
        this.initNode(document);

        document.querySelectorAll('app-dagjeuit').forEach((dagjeUitRoot) => new FilterDataCollector(dagjeUitRoot));

        document.querySelectorAll('[data-observe-mutations=true]').forEach((el) => {
            this.observeDom(el);
        });
    }

    observeDom(node) {
        // observe changes to the dom
        this.observer = new DOMMutationObserver(node);
        this.observer.addListener(DOMMutationObserver.EVENTS.change, (mutations) => {
            mutations.forEach((mutation) => {
                // iterate through addedNodes
                mutation.addedNodes.forEach((addedNode) => {
                    if (addedNode instanceof HTMLElement) {
                        this.initNode(addedNode);
                    }
                });
            });
        });
    }

    initNode(node) {
        let dataCollectors = {
            linkDataCollector: {
                eventString: 'click',
                elementSelector: 'a',
                handler: (event) => this.#handleClicks(event)
            },
            buttonDataCollector: {
                eventString: 'click',
                elementSelector: 'button',
                handler: (event) => this.#handleClicks(event)
            },
            detailsDataCollector: {
                eventString: 'toggle',
                elementSelector: 'details',
                handler: (event, extraData) => this.#handleDetailsToggle(event, extraData)
            },
            textAndMediaVideoDataCollector: {
                eventString: 'click',
                elementSelector: '.bodyItem__image--video',
                handler: (event) => this.#handleClicks(event)
            }
        };

        Object.keys(dataCollectors).forEach((key) => {
            let dataCollector = dataCollectors[key];
            this.#attachEventListener(node, dataCollector.eventString, dataCollector.elementSelector, dataCollector.handler);
        });
    }

    /**
     * Attach listeners that collect data
     */
    #attachEventListener(node, eventString, elementSelector, handler) {
        const elements = node.querySelectorAll(elementSelector + '[data-' + this.config.selectors.dataAttribute + ']');

        elements.forEach((el) => {
            const type = eventString + '.DataCollector';
            EventNamespaceHandlerUtils.removeEventListener(el, type);
            EventNamespaceHandlerUtils.addEventListener(el, type, handler);
        });
    }

    #handleClicks(event) {
        let dataAttribute = this.config.selectors.dataAttribute;
        const data = DataCollector.#parseData(event.currentTarget, dataAttribute);
        this.#createDataObject(data, event.detail?.extraEventData);
    }

    #handleDetailsToggle(event, extraData) {
        if (!event.currentTarget.classList.contains('collapse--disabled')) {
            let dataAttribute = this.config.selectors.dataAttribute;
            let data = DataCollector.#parseData(event.currentTarget, dataAttribute);
            /* eslint-disable camelcase */
            data = merge(
                {},
                {
                    event: 'event',
                    data: {
                        event_category: 'collapsible',
                        event_action: 'statechange',
                        event_label: 'state',
                        event_value: event.currentTarget.getAttribute('open') !== null ? 'expand' : 'collapse',
                        event_data: {
                            trigger: event.currentTarget.getElementsByTagName('summary')[0].innerText
                        }
                    }
                },
                data
            );
            /* eslint-enable */
            this.#createDataObject(data, typeof extraData !== 'undefined' ? extraData.extraEventData : undefined);
        }
    }

    /**
     * Convert data attribute to expected JSON format
     */
    #createDataObject(data, extraData) {
        //Either category and eventname is required (R42) or only event is required (DDQ)
        if (data && ((data.category && data.eventname) || data.event)) {
            publishTagmanagementEvent(data.category, data.eventname || data.event, merge({}, data.data, extraData));
        } else {
            console.error('Missing data for analytics', data);
        }
    }

    static #parseData(node, dataAttribute) {
        const data = node.dataset[dataAttribute];
        try {
            return JSON.parse(data);
        } catch {
            return data;
        }
    }

    subscribe(component, events) {
        for (let event in events) {
            switch (events[event]) {
                case 'Collapsible.EVENTS.collapse':
                case 'Collapsible.EVENTS.expand':
                    this.#collectCollapsibleData(component, events[event]);
                    break;
                case 'Form.EVENTS.updateConditionalField':
                    this.#collectFormData(component, events[event]);
                    break;
                case 'Overlay.EVENTS.overlayIsClosed':
                case 'Overlay.EVENTS.overlayIsOpen':
                    this.#collectOverlayData(component, events[event]);
                    break;
            }
        }
    }

    /**
     * Track form interaction and form values
     * @param component
     * @param event
     */
    #collectFormData(component, event) {
        component.addListener(event, (eventData) => {
            /* eslint-disable camelcase */
            let data = {
                event_category: 'form',
                event_action: 'statechange',
                event_label: 'state',
                event_value: event.split('.').slice(-1).pop(),
                event_data: {
                    name: component.node.name,
                    values: DOMUtils.serializeArray(component.node),
                    trigger: eventData.trigger
                }
            };
            /* eslint-enable */
            publishTagmanagementEvent(null, 'event', data);
        });
    }

    /**
     * All Overlay data collection logic goes here
     * @param component
     * @param event
     */
    #collectOverlayData(component, event) {
        component.addListener(event, (eventData) => {
            if (eventData) {
                /* eslint-disable camelcase */
                let data = {
                    event_category: 'overlay',
                    event_action: 'statechange',
                    event_label: 'state',
                    event_value: eventData.event,
                    event_data: eventData
                };
                /* eslint-enable */
                publishTagmanagementEvent(null, 'event', data);
            }
        });
    }

    /**
     * All collapsible and collapsible tile tracking go here
     * @param component
     * @param event
     */
    #collectCollapsibleData(component, event) {
        component.addListener(event, (eventData) => {
            /* eslint-disable camelcase */
            let data = {
                event_category: 'collapsible',
                event_action: 'statechange',
                event_label: 'state',
                event_value: event.split('.').slice(-1).pop(),
                event_data: {
                    trigger: eventData.trigger && eventData.trigger.length ? eventData.trigger.text().trim() : null
                }
            };
            /* eslint-enable */
            publishTagmanagementEvent(null, 'event', data);
        });
    }
}

export default new DataCollector();
