import Optimizely from '@optimizely/optimizely-sdk';
import LogUtils from './LogUtils';

class OptimizelyService {
    #instance;
    #user;

    async init() {
        if (window.NSDesignConfig?.optimizely?.enabled && window.NSDesignConfig?.optimizely?.sdkKey) {
            const sdkKey = window.NSDesignConfig.optimizely.sdkKey;
            const optimizelyInstance = Optimizely.createInstance({
                sdkKey: sdkKey,
                eventBatchSize: 10,
                eventFlushInterval: 1000
            });
            optimizelyInstance.onReady().then(({ success, reason }) => {
                if (!success) {
                    LogUtils.logErrorMessage(`Error initializing optimizely: ${reason}`);
                } else {
                    this.#instance = optimizelyInstance;
                }
            });
        }
    }

    decide(flag) {
        if (!this.#user) {
            const userId = this.#getUserId();
            if (userId) {
                this.#user = this.#instance?.createUserContext(userId);
            }
        }
        return this.#user?.decide(flag);
    }

    #getUserId() {
        try {
            let userId = localStorage?.getItem('nssid');
            if (!userId) {
                userId = localStorage?.getItem('tempoptimizelyuserid');
            } else {
                localStorage?.removeItem('tempoptimizelyuserid');
            }
            if (!userId) {
                userId = crypto.randomUUID();
                localStorage?.setItem('tempoptimizelyuserid', userId);
            }
            return userId;
        } catch (_e) {
            //ignore
        }
    }
}

export default new OptimizelyService();
