import ChatUtil from './ChatUtil';
import ChatObserver from './ChatObserver';
import { ChatClickSource } from './Avo';

class ChatLinks {
    chatLinkClass = 'chatLink';
    statusClass = 'chatLink__statusText';
    chatHref = 'nslivechat://';
    disabledClass = 'chatLink--disabled';

    init() {
        //Not checking for statusTexts is on purpose to prevent enabling chat but forgetting the texts
        if (window.NSDesignConfig && window.NSDesignConfig.chat) {
            let statusTexts = window.NSDesignConfig.chat.statusTexts;
            this.chatLinks = Array.from(document.querySelectorAll('a[href="' + this.chatHref + '"]'));
            this.chatLinks.forEach((chatLink) => {
                chatLink.classList.add(this.chatLinkClass);
            });
            this.setStatus(statusTexts.error, false);

            ChatObserver.addListener(ChatObserver.EVENTS.disabled, () => {
                this.setStatus(statusTexts.error, false);
            });
            ChatObserver.addListener(ChatObserver.EVENTS.offline, () => {
                this.setStatus(statusTexts.offline, false);
            });
            ChatObserver.addListener(ChatObserver.EVENTS.online, () => {
                this.setStatus(null, true);
            });

            this.chatLinks.forEach((chatLink) => {
                chatLink.addEventListener('click', (event) => {
                    event.preventDefault();

                    if (!ChatUtil.checkChatReady()) {
                        return false;
                    } else {
                        ChatUtil.showChatbot(ChatClickSource.CHAT_LINK);
                    }
                });
            });
        }
    }

    setStatus(text, enabled) {
        this.chatLinks.forEach((chatLink) => {
            if (enabled) {
                chatLink.classList.remove(this.disabledClass);
            } else {
                chatLink.classList.add(this.disabledClass);
            }

            if (text !== null) {
                let statusElement = chatLink.querySelector('.' + this.statusClass);
                if (statusElement) {
                    statusElement.textContent = text;
                } else {
                    let spanElement = document.createElement('span');
                    spanElement.className = this.statusClass;
                    spanElement.textContent = text;
                    chatLink.appendChild(spanElement);
                }
            } else {
                chatLink.querySelector('.' + this.statusClass)?.remove();
            }
        });
    }
}

export default new ChatLinks();
