import OverlayManager from '../OverlayManager';

class ContentOpener {
    #abortController = new AbortController();
    #clickHandler = (e) => {
        switch (this.config.type) {
            case 'Overlay':
                e.preventDefault();
                this.openOverlay();
                break;
            case 'OverlayReturn':
                e.preventDefault();
                this.overlayReturn();
                break;
        }
    };

    constructor(node, config) {
        this.node = node;
        this.config = config;

        this.node.addEventListener('click', this.#clickHandler, { signal: this.#abortController.signal });
    }

    openOverlay() {
        if (!this.config.fullHeight) {
            this.config.fullHeight = false;
        }
        OverlayManager.openById(this.config.contentId, this, this.config.break, this.config.fullHeight, this.config.siblingId);
    }

    overlayReturn() {
        const parent = document.querySelector('#' + this.config.contentId).parentElement;
        if (!this.config.break) {
            this.config.break = null;
        }
        if (!this.config.fullHeight) {
            this.config.fullHeight = false;
        }
        if (!this.config.siblingId) {
            this.config.siblingId = null;
        }

        OverlayManager.openById(this.config.contentId, this, this.config.break, parent, this.config.fullHeight, this.config.siblingId);
    }

    refocus() {
        // for accessibility, set focus back to opener button.
        this.node.focus();
    }

    destroy() {
        this.#abortController.abort();
        this.node = null;
        this.config = null;
    }
}
export default ContentOpener;
