import scrollToTarget from '../../../generic/utils/scrollToTarget';
import publishTagmanagementEvent from '../../../generic/utils/publishTagmanagementEvent';
import merge from 'lodash/merge';

class Footnote {
    static DEFAULT_CONFIG = {
        footnoteItemSelector: 'li.footnote__item',
        footnoteRefLinkSelector: 'a.footnote__item-refLink',
        footnoteRefClass: 'footnoteAnchor',
        describedById: 'footnoteHeader'
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, Footnote.DEFAULT_CONFIG, config);

        this.init();
    }

    init() {
        let footnotes = this.node.querySelectorAll(this.config.footnoteItemSelector);

        footnotes.forEach((footnote) => {
            if (!footnote.id || footnote.id.length <= 1) {
                return;
            }

            let footnoteRefs = document.querySelectorAll('a[href="#' + footnote.id + '"]');
            let footnoteRefLink = footnote.querySelector(this.config.footnoteRefLinkSelector);
            let footnoteRef = footnoteRefs ? footnoteRefs[0] : undefined;

            if (footnoteRef) {
                footnoteRef.classList.add(this.config.footnoteRefClass);
                footnoteRef.setAttribute('id', footnote.id + '-ref');
                footnoteRef.setAttribute('aria-describedby', this.config.describedById);

                // Click listener from ref to footnote
                footnoteRef.addEventListener('click', (event) => {
                    event.preventDefault();

                    this.goToTarget(footnote.id, true);
                });

                // And click listener from footnote to first instance of it's ref
                footnoteRefLink.addEventListener('click', (event) => {
                    event.preventDefault();

                    this.goToTarget(footnoteRef.id, false);
                });
            }
        });
    }

    goToTarget(targetId, targetIsFootnote) {
        let target = document.getElementById(targetId);
        let currentHash = window.location.hash.replace(/^.*#/, '');

        /* eslint-disable camelcase */
        let interactionEvent = {
            interaction_type: 'footnote',
            interaction_action: targetIsFootnote === true ? 'toFootnote' : 'toReference',
            interaction_location: 'body',
            interaction_data: {
                to: targetId
            }
        };
        /* eslint-enable */
        publishTagmanagementEvent(null, 'website_interaction', interactionEvent);

        // @TODO: Known a11y issue with iOS11.3 where target doesn't get read by Apple VoiceOver after scroll
        if (targetId === currentHash) {
            // Page is already on this hash, scroll to it;
            scrollToTarget(target, -1);
        } else {
            // Set hashchange listener
            const hashchangeHandler = () => {
                scrollToTarget(target, -1);
            };
            window.addEventListener('hashchange', hashchangeHandler, { once: true });
            // Listener set, so kick-off hash change
            window.location.hash = targetId;
        }
    }
}
export default Footnote;
