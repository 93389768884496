import { default as $ } from 'jquery';
import gsap from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import CSSPlugin from 'gsap/CSSPlugin';
import EasePack from 'gsap/EasePack';
import InertiaPlugin from 'gsap/InertiaPlugin';
import initResponsive from './behaviours/initResponsive';
import setViewportHeight from './behaviours/setViewportHeight';
import ComponentManager from './utils/ComponentManager';
import EventBus from './utils/EventBus';
import ChatLinks from './utils/ChatLinks';
import Relay42Observer from './utils/Relay42Observer';
import createRequirePatch from './behaviours/createRequirePatch';
import DataCollector from './utils/datacollector/DataCollector';
import OverlayManager from './ui/OverlayManager';
import viewportSize from 'viewport-size';
import InputModeDetector from './ui/InputModeDetector';
import initPolyfills from './behaviours/initPolyfills';
import CdpObserver from './utils/CdpObserver';
import ChatObserver from './utils/ChatObserver';
import Optimizely from './utils/OptimizelyService';
import initAvo from './utils/initAvo';
import ChatUtil from './utils/ChatUtil';

class NSDesign {
    constructor() {
        // expose jQuery to global scope for dependent projects (should be refactored)
        window.$ = window.jQuery = $;
        gsap.registerPlugin(ScrollToPlugin, InertiaPlugin, EasePack, CSSPlugin);
        gsap.defaults({
            ease: 'sine.inOut'
        });
        window.gsap = gsap;
    }

    async developerMode() {
        await import('./dev/GridReference');
        await import('./dev/DebugModeManager');
        await import('./dev/CartValueTester');
    }

    async templateMode() {
        await this.developerMode();
    }

    openOverlayById(id) {
        OverlayManager.openById(id);
    }

    relay42LoadingFinished(success) {
        Relay42Observer.handleRelay42LoadingFinished(success);
    }

    cdpLoadingFinished(success) {
        CdpObserver.handleCdpLoadingFinished(success);
    }

    init() {
        initPolyfills();
        initResponsive();
        setViewportHeight();
        createRequirePatch();

        window.eventBus = window.eventBus || EventBus;
        ComponentManager.init(document);
        initAvo();
        DataCollector.init();
        InputModeDetector.start();

        if (window.initialOverlay) {
            this.openOverlayById(window.initialOverlay);
        }

        // expose viewportsize lib, this is for testautomation
        window.viewportSize = viewportSize;

        //expose ChatObserver to be used in testautomation or other apps
        window.ChatObserver = ChatObserver;

        //expose a method to be able to open the chatbot from applications outside NSDesign
        window.openChatbot = (source) => {
            ChatUtil.showChatbot(source);
        };

        ChatLinks.init();
        ChatObserver.init();
        Optimizely.init();
    }
}

export default new NSDesign();
