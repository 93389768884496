/* global Sentry, DD_LOGS, DD_RUM */
class LogUtils {
    static logErrorMessage(errorMessage) {
        const error = new Error(errorMessage);
        if (typeof Sentry !== 'undefined') {
            try {
                Sentry.captureException(error);
            } catch (e) {
                //Ignore
            }
        }
        if (typeof DD_LOGS !== 'undefined') {
            try {
                DD_LOGS.logger.error(errorMessage);
            } catch (e) {
                //Ignore
            }
        }
        if (typeof DD_RUM !== 'undefined') {
            try {
                DD_RUM.addError(error);
            } catch (e) {
                //Ignore
            }
        }
    }
}

export default LogUtils;
