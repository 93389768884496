import EventEmitter from 'wolfy87-eventemitter';
import ChatUtil from './ChatUtil';

class ChatObserver extends EventEmitter {
    static CURRENT_TIMEOUT;

    EVENTS = {
        disabled: 'ChatObserver.EVENTS.disabled',
        offline: 'ChatObserver.EVENTS.offline',
        online: 'ChatObserver.EVENTS.online'
    };

    constructor() {
        super();
        this.currentState = null;

        this.addListener(this.EVENTS.online, () => {
            this.currentState = 'online';
        });

        this.addListener(this.EVENTS.offline, () => {
            this.currentState = 'offline';
        });

        this.addListener(this.EVENTS.disabled, () => {
            this.currentState = 'disabled';
        });
    }

    init() {
        if (window.NSDesignConfig?.chat?.enabled) {
            this.#waitForOnline();
        } else {
            this.emitEvent(this.EVENTS.disabled);
        }
    }

    getCurrentState() {
        return this.currentState;
    }

    //For test automation usage
    stopAutomaticUpdates() {
        if (ChatObserver.CURRENT_TIMEOUT) {
            clearTimeout(ChatObserver.CURRENT_TIMEOUT);
        }
    }

    #waitForOnline() {
        if (ChatUtil.isCognigyChatReady()) {
            this.emitEvent(this.EVENTS.online);
        } else {
            ChatObserver.CURRENT_TIMEOUT = setTimeout(() => this.#waitForOnline(), 250);
        }
    }
}

export default new ChatObserver();
