import ChatObserver from './ChatObserver';
import Avo from './Avo';

class ChatUtil {
    static showChatbot(source) {
        this.#showCognigyBot(source);
    }

    static #showCognigyBot(source) {
        window.openCognigyWebchat();

        Avo.chatStart({
            chatTool: 'cognigy',
            chatInitiator: 'click',
            chatClickSource: source
        });
    }

    //Checks if the chat is ready to chat. An extra check for the button.
    static checkChatReady() {
        return ChatObserver.getCurrentState() === 'online';
    }

    static isCognigyChatReady() {
        return typeof window.initWebchat === 'function' && typeof window.openCognigyWebchat === 'function';
    }
}

export default ChatUtil;
